<template>
  <section>
    <div class="showcase-content">
      <template v-if="countries && countries.length">
        <ShowcaseCountries
          :countries-list="countries"
          :active-country="activeCountry"

          @selectCountry="changeActiveCountry"
        />
        <div
          class="refresh-icon"
          @click="changeActiveShowcase('services')"
        >
          <img
            src="~/assets/img/showcase/refresh.svg"
            alt="refresh"
          >
        </div>
        <ShowcaseServices
          :services-list="servicesListForCountry"
          :is-services-list-loading="isServicesListForCountryLoading"
          :loading-services-ids="loadingServicesIds"

          @selectService="onByNumber"
        />
      </template>
      <div
        v-else
        class="lading-showcase"
      >
        {{ $t('loading') }}
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import type { GetCountriesApp } from '~/helpers/parsers/CountriesDTO';
import ShowcaseCountries from '~/components/home/showcase/showcase-countries/countries/CountriesList.vue';
import ShowcaseServices from '~/components/home/showcase/showcase-countries/services/ServicesList.vue';
import type { ServiceListWithPriceType } from '~/server/routes/api-front/prices/by-country/[country]';
import { useActivationService } from '~/composables/activation/useActivationService';
import { useUserAuth } from '~/composables/user/useUserAuth';
import type { ServerResponseCommonType } from '~/types/commons';

const {
  $i18n,
  $toast
} = useNuxtApp();
const { changeActiveShowcase } = useChangeShowcaseView();
const { getPhoneNumber } = useActivationService();
const { currentUser } = useUserAuth();
const storeCountries = countriesStore();
const { countryList } = storeToRefs(storeCountries);

const activeCountry = ref<GetCountriesApp | null>(null);
const defaultCountryId = 27;

const countries = computed(():GetCountriesApp[] => {
  if (countryList.value) {
    return countryList.value;
  }
  return [];
});

if (countries.value) {
  activeCountry.value = (countries.value.find((t) => t.id === defaultCountryId)) || null;
}

const {
  data: servicesListForCountry,
  refresh: getServicesListForCountry,
  pending: isServicesListForCountryLoading
} = await useCustomFetch<ServiceListWithPriceType[]>(() => `/api-front/prices/by-country/${activeCountry.value?.id}`, {
  immediate: false,
  watch: false,
  baseURL: '/'
});

await getServicesListForCountry();

async function changeActiveCountry (country: GetCountriesApp) {
  activeCountry.value = country;
  await getServicesListForCountry();
}

const loadingServicesIds: Ref<number[]> = ref([]);
const addIdForloadingServicesIds = (id: number) => {
  let _loadingServicesIds: number[] = [...loadingServicesIds.value];
  _loadingServicesIds.push(id);
  loadingServicesIds.value = _loadingServicesIds;
};
const removeIdForloadingServicesIds = (id: number) => {
  let _loadingServicesIds: number[] = [...loadingServicesIds.value];
  const findIndex = _loadingServicesIds.findIndex((t) => Boolean(t === id));
  _loadingServicesIds.splice(findIndex, 1);
  loadingServicesIds.value = _loadingServicesIds;
};

async function onByNumber (service: ServiceListWithPriceType) {
  if (currentUser) {

    // Устанавливаем событие загрузки для кнопки по ID сервиса
    addIdForloadingServicesIds(service?.id);

    const res = await getPhoneNumber({
      service: service.id,
      multiple_sms: 0,
      method: 'phone',
      api_key: currentUser.api_token,
      country: +activeCountry!.value!.id! || 0,
    }).catch((err: ServerResponseCommonType) => {
      $toast.error(err.message);
      removeIdForloadingServicesIds(service?.id);
      return null;
    });
    if (res) {
      $toast.success($i18n.t('toast.buyNumberSuccess'))
      removeIdForloadingServicesIds(service?.id);
      navigateTo('/cabinet');
    }
    return;
  }
  navigateTo('/authorization');
}
</script>

<style scoped lang="scss">
.showcase-content {
	display: flex;
	gap: 4px;
	max-height: 660px;
	height: 100%;
	overflow: hidden;
	position: relative;

  .refresh-icon {
    &:hover {
      opacity: 0.8;
    }
  }

	& > section {
		flex: 1;
	}

  @media screen and (max-width: 1024px) {
    gap: 50px;
    > section {
      border-radius: 0;
    }
  }
}

@media screen and (max-width: 991px) {
  .showcase-content {
    flex-direction: column;
  }
}

</style>
